// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import './controllers';

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Rubik:300,400,500,600,700,800'],
  },
});