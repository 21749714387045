import { Controller } from "@hotwired/stimulus"

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import bar charts, all suffixed with Chart
import { BarChart } from 'echarts/charts';
// Import the tooltip, title, rectangular coordinate system, dataset and transform components
// all suffixed with Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features';
// Import the Canvas renderer
// Note that introducing the CanvasRenderer or SVGRenderer is a required step
import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';

export default class extends Controller {
  connect() {

    // Register the required components
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      BarChart,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
      SVGRenderer
    ]);

    // initialize the echarts instance
    let myChart = echarts.init(document.querySelector('#graph_two'), { renderer: 'canvas' });
    let labels = JSON.parse(document.querySelector('#graph_two').getAttribute('data-labels'));
    let positive_values = JSON.parse(document.querySelector('#graph_two').getAttribute('data-positive-values'));
    let negative_values = JSON.parse(document.querySelector('#graph_two').getAttribute('data-negative-values'));
    let base_values = JSON.parse(document.querySelector('#graph_two').getAttribute('data-base-values'));

    positive_values.forEach(function (item, i) { if (item == 0) positive_values[i] = "-"; })
    negative_values.forEach(function (item, i) { if (item == 0) negative_values[i] = "-"; })

    // Draw the chart
    myChart.setOption({
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
        borderColor: "transparent"
      },
      xAxis: {
        type: 'category',
        data: labels,
        axisLabel: {
          show: true,
          interval: 0
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: false,
        splitLine: {
          show: false
        },
      },
      series: [
        {
          name: 'Placeholder',
          type: 'bar',
          stack: 'Total',
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          data: base_values
        },
        {
          name: 'Positive',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position: 'top',
            formatter: function (d) {
              return new Intl.NumberFormat('en-US').format(d.data.label);
            }
          },
          data: positive_values,
          color: '#00B4D8'
        },
        {
          name: 'Negative',
          type: 'bar',
          stack: 'Total',
          data: negative_values,
          color: '#00B4D8'
        }
      ]
    });
  }
}
