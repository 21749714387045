import ApplicationController from '../support/application_controller';
import Cookies from 'universal-cookie';

export default class Cart extends ApplicationController {

  add(event) {

    const cookies = new Cookies();

    let items = cookies.get('cart_items');

    if (!items) { items = [] }

    items.push(event.params.itemId)

    cookies.set('cart_items', [...new Set(items)], { path: '/' });

  }

  remove(event) {

    const cookies = new Cookies();

    let items = cookies.get('cart_items');

    if (!items) { items = [] }

    items = items.filter(id => id != event.params.itemId)

    cookies.set('cart_items', [...new Set(items)], { path: '/' });

    this.element.remove();

    const cartListController = this.getControllerByIdentifier("cart-list");

    cartListController.updateTotal();

  }

  change() {
    window.location.reload();
  }

}