import { Controller } from '@hotwired/stimulus'

export default class ApplicationController extends Controller {

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}