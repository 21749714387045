import ApplicationController from '../support/application_controller';

export default class CartList extends ApplicationController {

  static targets = ["total", "price"];

  connect(){
    this.updateTotal()
  }
  
  updateTotal() {

    console.log('updateTotal')

    const total = this.priceTargets.map(item => parseInt(item.dataset.priceValue)).reduce((partialSum, a) => partialSum + a, 0) / 100;

    this.totalTarget.innerHTML = `€${total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

  }
}