import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  show() {
    let passwordInput = document.querySelector("#user_password");
    let passwordConfirmInput = document.querySelector("#user_password_confirmation");
    let showButton = document.querySelectorAll(".show_pswd_btn");
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      passwordConfirmInput.type = "text";
      showButton.stopPropagation();
    } else {
      passwordInput.type = "password";
      passwordConfirmInput.type = "password";
      showButton.stopPropagation();
    }
  }
}