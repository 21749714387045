import { Controller } from "@hotwired/stimulus"

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import bar charts, all suffixed with Chart
import { BarChart } from 'echarts/charts';
// Import the tooltip, title, rectangular coordinate system, dataset and transform components
// all suffixed with Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from 'echarts/components';
// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features';
// Import the Canvas renderer
// Note that introducing the CanvasRenderer or SVGRenderer is a required step
import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';

export default class extends Controller {
  connect() {

    // Register the required components
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      BarChart,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
      SVGRenderer
    ]);

    // initialize the echarts instance
    let myChart = echarts.init(document.querySelector('#graph'), { renderer: 'canvas' });
    let years = JSON.parse(document.querySelector('#graph').getAttribute('data-years'));
    let incomes = JSON.parse(document.querySelector('#graph').getAttribute('data-incomes'));

    // Draw the chart
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          axisLabel: {
            show: true,
            interval: 0
          },
          type: 'category',
          data: years,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: false,
          splitLine: {
            show: false
          },
        }
      ],
      series: [
        {
          label: {
            show: true,
            position: 'top',
            color: '#03045E',
            formatter: function (d) {
              return new Intl.NumberFormat('en-US').format(d.data);
            }
          },
          name: 'Value of Production',
          type: 'bar',
          barWidth: '60%',
          data: incomes,
          color: '#00B4D8'
        }
      ]
    });
  }
}



